import type { Config } from "tailwindcss";
import formsPlugin from "@tailwindcss/forms";

export default {
  content: ["./index.html", "./src/**/*.tsx"],
  plugins: [formsPlugin],
  theme: {
    fontFamily: {
      sans: ["Roboto", "sans-serif"],
      "can-inter": ["Inter", "sans-serif"],
    },
    extend: {
      borderRadius: {
        "can-2xl": "1rem",
      },
      backgroundImage: {
        "can-gradient-to-r-80":
          "linear-gradient(80deg, var(--tw-gradient-stops))",
      },
      boxShadow: {
        "can-light-box": "2px 4px 12px 0px rgba(122, 128, 147, 0.25)",
      },
      colors: {
        "can-silver-cloud": "#CACCD1",
        "can-silver-gray": "#F4F4F4",
        "can-sky-blue": "#9CCBEA",
        "can-tranquil-azure": "#60A6D3",
        "can-forest-teal": "#168B6B",
        "can-mystic-aqua": "#5DB4A1",
        "can-slate-blue-gray": "#616161",
        "can-midnight-steel": "#4D5570",
        "can-stormy-sky": "#7A8093",
        "can-russet": "#E55743",
      },
      animation: {
        "can-load": "can-load 2s ease",
        "can-loading-gradient": "can-loading-gradient 3s ease infinite",
      },
      keyframes: {
        "can-loading-gradient": {
          "0%": {
            "background-position": "0% 50%",
          },
          "50%": {
            "background-position": "100% 51%",
          },
          "100%": {
            "background-position": "0% 50%",
          },
        },
        "can-load": {
          from: {
            right: "100%",
          },
          to: {
            right: "0%",
          },
        },
      },
    },
  },
} satisfies Config;
