import React from "react";
import { Button } from "../button/button";

type DialogContext = {
  dialog?: React.ReactNode;
  openDialog: (dialog: React.ReactNode) => void;
  closeDialog: () => void;
};
const Context = React.createContext<DialogContext | undefined>(undefined);

const useDialogProvider = () => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error(
      // eslint-disable-next-line lingui/no-unlocalized-strings
      "useDialogProvider only available within <DialogProvider /> component",
    );
  }

  return context;
};

const DialogProvider = ({ children }: React.PropsWithChildren) => {
  const [dialog, setDialog] = React.useState<
    DialogContext["dialog"] | undefined
  >();

  const close = () => {
    setDialog(undefined);
  };

  const open = (dialog: DialogContext["dialog"]) => {
    setDialog(dialog);
  };

  return (
    <Context.Provider value={{ dialog, closeDialog: close, openDialog: open }}>
      {children}
      {dialog}
    </Context.Provider>
  );
};

const Dialog = (props: React.PropsWithChildren) => {
  return (
    <Backdrop>
      <div
        role="dialog"
        className="flex flex-col gap-y-6 rounded-2xl bg-white p-6 shadow-can-light-box"
        {...props}
      />
    </Backdrop>
  );
};

const Backdrop = (props: React.PropsWithChildren) => (
  <div
    className="fixed inset-0 z-[1000] flex items-center justify-center bg-[rgba(97,97,97,0.60)] backdrop-blur"
    {...props}
  />
);

const Actions = (props: React.PropsWithChildren) => (
  <div className="flex justify-between" {...props} />
);

const Cancel = (
  props: React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>,
) => <Button variant="secondary" {...props} />;

const Confirm = (
  props: React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>,
) => <Button variant="primary" {...props} />;

const Title = (props: React.PropsWithChildren) => (
  <h1 className="text-xl font-bold text-can-midnight-steel" {...props} />
);

const Content = (props: React.PropsWithChildren) => (
  <p className="text-base font-normal" {...props} />
);

Dialog.Actions = Actions;
Dialog.Confirm = Confirm;
Dialog.Cancel = Cancel;
Dialog.Content = Content;
Dialog.Title = Title;

export { Dialog, DialogProvider, useDialogProvider };
