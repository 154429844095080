import { Metadata } from "@/common/components/metadata/metadata";
import { Currency, useCurrency } from "@/common/hooks/use-currency";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import { Storage as TStorage, storagesQuery } from "./api";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "@/common/components/spinner/spinner";
import { Failed, Info } from "@/common/components/info/info";
import GarageOpenSvg from "@icons/garage-open.svg?react";
import BoxesSvg from "@icons/boxes.svg?react";
import AngleSmallDownSvg from "@icons/angle-small-down.svg?react";
import PlusSvg from "@icons/plus.svg?react";
import PencilSvg from "@icons/pencil.svg?react";
import Gears from "@icons/gears.svg?url";
import { path as managementPath } from "./storages.management";
import { useIsMobile } from "@/common/hooks/use-media-query";
import { useScrollbarToggle } from "@/common/hooks/use-scrollbar-toggle";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { TwoColumnLayout } from "../layout";
import { CommodityCard } from "./components/commodity-card/commodity-card";
import { initialParams, useCurrencyParams } from "../shared/currency.params";
import { Plural, Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { sum } from "@/common/utils/array";
import { currencyFormatter } from "@/common/services/formatter";
import React from "react";
import {
  SearchField,
  useSearchFieldParams,
} from "@/common/components/search-field/search-field";

const path = "storages";

const Storages = () => {
  const { _ } = useLingui();
  const { offerId } = useParams<"offerId">();
  const [CurrencySwitch, currency] = useCurrency();
  const [search] = useSearchFieldParams();
  const storages = useQuery(storagesQuery({ currency, search }));
  const location = useLocation();
  const isRightSideOpen =
    location.pathname.includes("management") ||
    location.pathname.includes("offers");
  useScrollbarToggle(isRightSideOpen);

  let jsx: React.ReactNode = <ManagementLink />;

  if (storages.status === "pending") {
    jsx = (
      <div className="flex basis-full items-center justify-center">
        <Spinner />
      </div>
    );
  } else if (storages.status === "error") {
    jsx = <Failed error={storages.error} />;
  } else if (storages.status === "success") {
    if (!storages.data) {
      jsx = (
        <>
          <Info>
            <Trans>Ve vaší společnosti zatím neexistují žádné sklady.</Trans>
          </Info>
          <ManagementLink />
        </>
      );
    } else {
      jsx = (
        <>
          <SearchField />
          {[...storages.data].map(([company, storages]) => (
            <section className="flex flex-col gap-y-4" key={company.id}>
              <div className="flex justify-between">
                <h2 className="text-xl font-bold text-can-midnight-steel">
                  {company.name}
                </h2>
                <Link className="flex items-center gap-2" to="management/new">
                  <Trans>Nový sklad</Trans>
                  <PlusSvg />
                </Link>
              </div>
              <ul className="flex flex-col gap-y-6">
                {storages.map((storage) => (
                  <Storage key={storage.id} storage={storage}>
                    <ul className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 2xl:grid-cols-3">
                      {storage.offers.map((offer) => (
                        <li
                          key={offer.id}
                          className={
                            offerId === offer.id
                              ? "[&_article]:bg-can-silver-gray"
                              : ""
                          }
                        >
                          <SearchParamsLink
                            to={`${storage.id}/offers/${offer.id}`}
                          >
                            <CommodityCard>
                              <CommodityCard.Title
                                crop={{
                                  harvestYear: offer.harvestYear,
                                  name: offer.crop.name,
                                  mark: offer.crop.mark,
                                }}
                              />
                              <CommodityCard.Price
                                tons={offer.amount}
                                pricePerTon={offer.lastPrice}
                                currency={currency}
                              />
                              <CommodityCard.Stored tons={offer.amount} />
                              <CommodityCard.ProgressBar
                                values={{
                                  freeAmount: offer.amount,
                                  orderAmount: offer.orderAmount,
                                  totalAmount: offer.totalAmount,
                                }}
                              />
                              <CommodityCard.Available
                                order={offer.orderAmount}
                                available={offer.amount}
                                tons={offer.totalAmount}
                              />
                            </CommodityCard>
                          </SearchParamsLink>
                        </li>
                      ))}
                    </ul>
                    <Overview
                      currency={currency}
                      tons={storage.offers.map((o) => o.amount).reduce(sum, 0)}
                      price={storage.offers
                        .filter((o) => o.lastPrice)
                        .map((o) => o.amount * o.lastPrice!)
                        .reduce(sum, 0)}
                    />
                    <Actions storageId={storage.id} />
                  </Storage>
                ))}
              </ul>
            </section>
          ))}
          <ManagementLink />
        </>
      );
    }
  }

  return (
    <TwoColumnLayout
      left={{
        header: (
          <>
            <h1 className="text-2xl font-bold text-can-forest-teal">
              <Trans>Sklady</Trans>
            </h1>
            {CurrencySwitch}
          </>
        ),
        content: (
          <>
            <Metadata title={_(msg`Sklady`)} />
            {jsx}
          </>
        ),
      }}
      right={isRightSideOpen}
    />
  );
};

const Actions = ({ storageId }: { storageId: string }) => {
  return (
    <div className="mt-6 flex justify-between">
      <SearchParamsLink
        className="flex items-center gap-2"
        // TODO create a new route
        to={`management/${storageId}/crops/new`}
      >
        <PlusSvg />
        <Trans>Přidat komoditu</Trans>
      </SearchParamsLink>
      <SearchParamsLink
        className="flex items-center gap-2"
        to={`management/${storageId}`}
      >
        <PencilSvg />
        <Trans>Upravit sklad</Trans>
      </SearchParamsLink>
    </div>
  );
};

const Overview = ({
  price,
  tons,
  currency,
}: {
  price: number;
  tons: number;
  currency: Currency;
}) => {
  return (
    <p
      role="status"
      className="mt-4 flex gap-2 rounded-2xl bg-can-silver-gray px-4 py-3 text-xs"
    >
      <BoxesSvg />
      <Trans>Skladem:</Trans>{" "}
      <Plural
        value={tons}
        _0="# tun"
        one="# tuna"
        few="# tuny"
        many="# tun"
        other="# tun"
      />{" "}
      |{" "}
      <Trans>Hodnota (nyní): {currencyFormatter(currency).format(price)}</Trans>
    </p>
  );
};

const ManagementLink = () => (
  <SearchParamsLink
    to={managementPath}
    style={{
      // eslint-disable-next-line lingui/no-unlocalized-strings
      background: `url(${Gears}) left 16px center no-repeat`,
    }}
    className="mb-8 mr-6 mt-auto rounded-2xl border border-can-forest-teal px-4 py-2 text-center text-can-forest-teal"
  >
    <Trans>Spravovat sklady</Trans>
  </SearchParamsLink>
);

const Storage = ({
  children,
  storage,
}: React.PropsWithChildren<{
  storage: TStorage;
}>) => {
  const isMobile = useIsMobile();

  return (
    <li key={storage.id} className="last-of-type:mb-8">
      <details open={!isMobile} className="group">
        <summary className="flex list-none flex-col">
          <h3 className="flex items-center gap-x-2 font-bold text-can-forest-teal [&_path]:fill-[currentColor]">
            <GarageOpenSvg className="h-4 w-4" />
            {storage.mark ? (
              <mark className="text-can-forest-teal">{storage.name}</mark>
            ) : (
              storage.name
            )}
            <AngleSmallDownSvg className="ml-auto rotate-180 group-open:rotate-0" />
          </h3>
        </summary>
        {children}
      </details>
    </li>
  );
};

const StoragesParams = ({ children }: React.PropsWithChildren) => {
  const { params } = useCurrencyParams();
  const { pathname } = useLocation();

  if (!params) {
    return <Navigate replace to={`${pathname}?${initialParams}`} />;
  }

  return <>{children}</>;
};

export { path, Storages, StoragesParams };
