import { ProductFruits } from "react-product-fruits";
import { getEnvironmentVariable } from "../services/env";

type Props = {
  language: string;
  user: {
    email: string;
    username: string;
  };
};

const ProductFruitsProvider = (props: Props) => {
  return (
    <ProductFruits
      workspaceCode={getEnvironmentVariable("PRODUCT_FRUITS_WORKSPACE_CODE")}
      language={props.language}
      user={props.user}
    />
  );
};

export { ProductFruitsProvider };
