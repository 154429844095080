import { graphqlClient } from "@/api/graphql";
import { currencyFormatter } from "@/common/services/formatter";
import { graphql } from "@/generated/digitalnisklady.cz";
import {
  ContractQuery,
  ContractStatus,
} from "@/generated/digitalnisklady.cz/graphql";
import { queryOptions } from "@tanstack/react-query";
import { DateTime, Interval } from "luxon";
import { z } from "zod";
import { newestFirst } from "./utils";

// eslint-disable-next-line lingui/no-unlocalized-strings
const contractsDocument = graphql(`
  query Contracts($companyId: ID!) {
    contracts(companyId: $companyId) {
      edges {
        node {
          id
          amount
          offer {
            crop {
              name
            }
            storage {
              label
              company {
                name
              }
            }
          }
          status
          timeCreated
          timeProcessed
          currency
          price
          finalPrice
          customTransport
          note
          traderSigned
        }
      }
    }
  }
`);

const contractsQuery = ({ companyId }: { companyId: string }) =>
  queryOptions({
    queryKey: ["contracts", companyId],
    queryFn: () =>
      graphqlClient.request(contractsDocument, {
        companyId,
      }),
    select: (data) => {
      return data.contracts?.edges
        ?.map((edge) => createSafeContract(edge?.node))
        .toSorted(newestFirst);
    },
  });

// TODO pass currency
const totalSalesQuery = ({
  companyId,
  dateFrom,
  dateTo,
}: {
  companyId: string;
  dateFrom: DateTime<true> | undefined;
  dateTo: DateTime<true> | undefined;
}) =>
  queryOptions({
    ...contractsQuery({ companyId }),
    queryKey: [
      "contracts",
      companyId,
      "successful",
      dateFrom ? dateFrom.toISO()! : "",
      dateTo ? dateTo.toISO()! : "",
    ],
    select: (data) => {
      const sum =
        data.contracts?.edges
          ?.filter((edge) => edge?.node?.status === ContractStatus.Signed)
          .filter((edge) => {
            if (!dateFrom || !dateTo) {
              return true;
            }

            return Interval.fromDateTimes(
              dateFrom,
              dateTo.endOf("day"),
            ).contains(
              DateTime.fromMillis(edge?.node?.timeProcessed * 1000).startOf(
                "day",
              ),
            );
          })
          .reduce((sum, contractPrice) => {
            return (
              sum +
              (contractPrice?.node?.finalPrice ?? 0) *
                (contractPrice?.node?.amount ?? 0)
            );
          }, 0) ?? 0;

      return currencyFormatter("CZK").format(sum);
    },
  });

// eslint-disable-next-line lingui/no-unlocalized-strings
const contractDocument = graphql(`
  query Contract($id: ID!) {
    contract(id: $id) {
      userSignature {
        id
        slug
        status
      }
      confirmation {
        id
        completed
        documents {
          name
          url
        }
      }
      id
      amount
      offer {
        crop {
          name
        }
        storage {
          label
          company {
            name
          }
        }
      }
      status
      timeCreated
      timeProcessed
      currency
      price
      finalPrice
      customTransport
      note
      traderSigned
    }
  }
`);

const contractSchema = z.object({
  id: z.string(),
  crop: z.object({
    name: z.string(),
  }),
  amount: z.number(),
  status: z.nativeEnum(ContractStatus),
  timeCreated: z.number().transform((value) => DateTime.fromSeconds(value)),
  timeProcessed: z
    .number()
    .or(z.null())
    .transform((value) => (value ? DateTime.fromSeconds(value) : undefined)),
  currency: z.enum(["CZK", "EUR"]),
  price: z.number(),
  finalPrice: z.number(),
  customTransport: z.boolean(),
  note: z.string().nullable(),
  company: z.object({
    name: z.string(),
  }),
  storage: z.object({
    name: z.string(),
  }),
  confirmation: z.null().or(
    z.object({
      id: z.string(),
      completed: z.boolean(),
      documents: z.array(
        z.object({
          name: z.string(),
          url: z.string(),
        }),
      ),
    }),
  ),
  traderSigned: z.boolean(),
});

const signatureSchema = z
  .object({
    id: z.string(),
    slug: z.string(),
    status: z.union([
      z.literal("COMPLETED"),
      z.literal("AWAITING"),
      z.literal("SENT"),
      z.literal("OPENED"),
    ]),
  })
  .or(z.null());

const contractQuery = ({ id }: { id: string }) =>
  queryOptions({
    queryKey: ["contract", id],
    queryFn: () =>
      graphqlClient.request(contractDocument, {
        id,
      }),
    select: ({ contract }) => {
      const safeContract = createSafeContract(contract);
      const safeSignature = signatureSchema.parse(contract?.userSignature);
      return { ...safeContract, userSignature: safeSignature };
    },
  });

const createSafeContract = (maybeContract: ContractQuery["contract"]) =>
  contractSchema.parse({
    id: maybeContract?.id,
    amount: maybeContract?.amount,
    crop: maybeContract?.offer?.crop,
    status: maybeContract?.status,
    timeCreated: maybeContract?.timeCreated,
    timeProcessed: maybeContract?.timeProcessed,
    currency: maybeContract?.currency,
    price: maybeContract?.price,
    finalPrice: maybeContract?.finalPrice,
    customTransport: maybeContract?.customTransport,
    note: maybeContract?.note,
    company: maybeContract?.offer?.storage?.company,
    storage: {
      name: maybeContract?.offer?.storage?.label,
    },
    confirmation: maybeContract?.confirmation ?? null,
    traderSigned: maybeContract?.traderSigned,
  });

// eslint-disable-next-line lingui/no-unlocalized-strings
const confirmationDocument = graphql(`
  query Confirmation($id: ID!) {
    contract(id: $id) {
      userSignature {
        id
        slug
        status
      }
      traderSigned
      confirmation {
        id
        completed
        documents {
          name
          url
        }
      }
    }
  }
`);

const confirmationQuery = ({ contractId }: { contractId: string }) =>
  queryOptions({
    queryKey: ["contract", contractId, "confirmation"],
    queryFn: () =>
      graphqlClient.request(confirmationDocument, {
        id: contractId,
      }),
    select: (data) => {
      // FIXME when `signedByTrader` flag is false, neither confirmation nor docusealSlug make much sense here
      const safeContractSchema = z
        .object({
          docusealSlug: z.string().or(z.undefined()),
          signedByTrader: z.boolean(),
          confirmation: z.null().or(
            z.object({
              id: z.string(),
              completed: z.boolean(),
              documents: z.array(
                z.object({
                  name: z.string(),
                  url: z.string(),
                }),
              ),
            }),
          ),
        })
        .or(z.null());
      return safeContractSchema.parse({
        signedByTrader: data.contract?.traderSigned,
        confirmation: data.contract?.confirmation,
        docusealSlug: data.contract?.userSignature?.slug,
      });
    },
  });

export { contractQuery, contractsQuery, totalSalesQuery, confirmationQuery };
