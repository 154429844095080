import { currencyFormatter } from "@/common/services/formatter";

type Price = {
  id: string;
  value: number;
  currency: string;
  quarter: string;
};

type Props = {
  className?: string;
  prices: Array<Price | null>;
  renderPrice?: (price: Props["prices"][number]) => React.ReactNode;
};

const PriceList = ({ renderPrice, prices, className = "" }: Props) => (
  <ul
    className={`grid grid-cols-4 rounded-2xl border border-can-silver-cloud bg-can-silver-gray px-2 text-xs text-can-midnight-steel  ${className}`}
  >
    {prices.map((price, i) => (
      <li
        key={i}
        className="flex flex-col flex-wrap gap-y-2 border-r border-can-silver-cloud [&:nth-child(4)]:border-none"
      >
        {renderPrice ? renderPrice(price) : <PriceItem price={price} />}
      </li>
    ))}
  </ul>
);

const PriceItem = ({ price }: { price: Props["prices"][number] }) =>
  price ? (
    <>
      <b className="px-2 pt-2">
        {price.value
          ? currencyFormatter(price.currency).format(price.value)
          : null}
      </b>
      <span className="px-2 pb-2">{price.quarter.replace(" ", "/")}</span>
    </>
  ) : null;

export { PriceList, PriceItem };
export type { Price };
