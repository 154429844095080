import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { FullscreenModalLayout } from "../layout";
import AngleLeft from "@icons/angle-left.svg?react";
import { PageTitle } from "@/common/components/page-title/page-title";
import { Spacer } from "@/common/components/spacer/spacer";
import { Trans, t } from "@lingui/macro";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { storageQuery } from "../shared/api/get-storage";
import { useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { OfferPropertiesList } from "@/common/components/offer-properties-list/offer-properties-list";
import { cropsQuery, useAddCrop } from "./api";
import { Crop, CropList } from "./components/crop-list/crop-list";
import { partition } from "lodash";
import { Info } from "@/common/components/info/info";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { InputField } from "@/common/components/form/input-field/input-field";
import { Button } from "@/common/components/button/button";

const path = "crops/new";

const NewCrop = () => {
  const { id } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(id, "Storage id is not defined");
  const queryClient = useQueryClient();
  const storage = useSuspenseQuery(storageQuery(id));
  const { currentCrop, handleCropSelect, handleCropClear } = useCurrentCrop();
  const cropsInStorage = storage.data.offers.map((o) => o.crop);
  const availableCrops = useAvailableCrops(cropsInStorage);
  const addCropMutation = useAddCrop();
  const navigate = useNavigate();

  const handleSubmit = (data: Fields) => {
    invariant(currentCrop);
    addCropMutation.mutate(
      {
        storageId: id,
        storage: {
          zip: storage.data.postalCode,
          crops: {
            id: currentCrop.id,
            tons: data.tons,
          },
          label: storage.data.name,
          companyId: storage.data.company.id,
          address: storage.data.address,
        },
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries();
          navigate("../..", {
            relative: "path",
          });
        },
      },
    );
  };

  return (
    <FullscreenModalLayout
      headerButton={
        currentCrop ? (
          <button onClick={handleCropClear}>
            <AngleLeft />
          </button>
        ) : (
          <SearchParamsLink relative="path" to="../.." replace>
            <AngleLeft />
          </SearchParamsLink>
        )
      }
    >
      <Spacer className="h-6" />
      <PageTitle>
        <Trans>Přidat komoditu</Trans>
      </PageTitle>
      <OfferPropertiesList
        properties={[
          {
            label: <Trans>Adresa</Trans>,
            value: storage.data.address,
          },
          {
            label: <Trans>Farma</Trans>,
            value: storage.data.company.name,
          },
          {
            label: <Trans>Sklad</Trans>,
            value: storage.data.name,
          },
        ]}
      />
      <Spacer className="h-6" />
      {currentCrop ? (
        <CropForm onSubmit={handleSubmit} />
      ) : availableCrops.length > 0 ? (
        <CropList onClick={handleCropSelect} crops={availableCrops} />
      ) : (
        <Info>
          <Trans>Ve skladu jsou již všechny dostupné komodity</Trans>
        </Info>
      )}
    </FullscreenModalLayout>
  );
};

type Fields = {
  tons: number;
};

const CropForm = ({ onSubmit }: { onSubmit: (data: Fields) => void }) => {
  const form = useForm<{ tons: number }>();

  return (
    <FormProvider {...form}>
      <form id="crop-form" onSubmit={form.handleSubmit(onSubmit)}>
        <InputField<Fields>
          step="0.1"
          type="number"
          label={<Trans>Skladem tun</Trans>}
          name="tons"
          options={{
            required: t`Vyplňte prosím množství`,
            valueAsNumber: true,
            min: {
              value: 1,
              message: t`Množství musí být vyšší než 1 tuna`,
            },
          }}
        />
      </form>
      <Button.Container>
        <Button variant="primary" form="crop-form">
          <Trans>Uložit</Trans>
        </Button>
      </Button.Container>
    </FormProvider>
  );
};

const useCurrentCrop = () => {
  const [currentCrop, setCurrentCrop] = React.useState<Crop>();

  const handleCropSelect = (crop: Crop) => {
    setCurrentCrop(crop);
  };

  const handleCropClear = () => {
    setCurrentCrop(undefined);
  };

  return { handleCropSelect, handleCropClear, currentCrop };
};

const useAvailableCrops = (
  existingCrops: Array<{ id: string; name: string }>,
) => {
  const allCrops = useSuspenseQuery(cropsQuery());
  const [, availableCrops] = partition(allCrops.data, (crop) =>
    existingCrops.map((crop) => crop.id).includes(crop.id),
  );

  return availableCrops;
};

export { NewCrop, path };
