import { graphqlClient } from "@/api/graphql";
import { getThisYear } from "@/common/utils/trading";
import { graphql } from "@/generated/digitalnisklady.cz";
import { Currency } from "@/generated/digitalnisklady.cz/graphql";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// eslint-disable-next-line lingui/no-unlocalized-strings
const editStorageDocument = graphql(`
  mutation EditStorage($id: ID!, $storage: StorageInput!) {
    editStorage(id: $id, storage: $storage) {
      id
    }
  }
`);

// eslint-disable-next-line lingui/no-unlocalized-strings
const addOfferDocument = graphql(`
  mutation AddOffer($offer: OfferInput!) {
    addOffer(offer: $offer) {
      id
    }
  }
`);

type EditPayload = {
  name: string;
  companyId: string;
  address: string;
  zip: string;
};

const useEditStorage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess: async (result) => {
      await queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === "user" ||
          (query.queryKey[0] === "storage" &&
            query.queryKey[1] === result.editStorage?.id),
      });
    },
    mutationFn: async ({ id, storage }: { id: string; storage: EditPayload }) =>
      await graphqlClient.request(editStorageDocument, {
        id,
        storage: {
          address: storage.address,
          label: storage.name,
          company: storage.companyId,
          currency: Currency.Czk,
          zip: storage.zip,
        },
      }),
  });
};

const useAddCrop = () => {
  return useMutation({
    mutationFn: async (payload: {
      storageId: string;
      storage: {
        zip: string;
        label: string;
        crops: {
          id: string;
          tons: number;
        };
        companyId: string;
        address: string;
      };
    }) => {
      await graphqlClient.request(editStorageDocument, {
        id: payload.storageId,
        storage: {
          address: payload.storage.address,
          company: payload.storage.companyId,
          label: payload.storage.label,
          crops: [payload.storage.crops.id],
          currency: Currency.Czk,
          zip: payload.storage.zip,
        },
      });

      await graphqlClient.request(addOfferDocument, {
        offer: {
          storage: payload.storageId,
          crop: payload.storage.crops.id,
          amount: payload.storage.crops.tons,
          yearHarvested: getThisYear(),
        },
      });
    },
  });
};

export { useEditStorage, useAddCrop };
