import { Dialog } from "@/common/components/dialog/dialog";
import { useToggle } from "@/common/hooks/use-toggle";
import { getUser } from "@/common/services/user";
import { useAuth0 } from "@auth0/auth0-react";
import { Trans } from "@lingui/macro";
import React from "react";
import invariant from "tiny-invariant";

const CompanyEditContext = React.createContext<(() => void) | undefined>(
  undefined,
);

const useCompanyEditProvider = () => {
  const ctx = React.useContext(CompanyEditContext);

  invariant(
    ctx,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "CompanyEditContext only available within <ContratSignGuard />",
  );

  return ctx;
};

const CompanyEditGuard = ({ children }: React.PropsWithChildren) => {
  const dialog = useToggle();

  return (
    <CompanyEditContext.Provider value={dialog.setOn}>
      {children}
      {dialog.on ? (
        <Dialog>
          <Dialog.Title>
            <Trans>Akci nelze dokončit</Trans>
            <Dialog.Content>
              <Trans>Nemáte oprávnění ke změně informací o společnosti.</Trans>
            </Dialog.Content>
            <Dialog.Content>
              <Trans>
                Kontaktujte podporu{" "}
                <a href="mailto:help@cleverfarm.ag">help@cleverfarm.ag</a>.
              </Trans>
            </Dialog.Content>
          </Dialog.Title>
          <Dialog.Actions>
            <Dialog.Confirm onClick={dialog.setOff}>
              <Trans>Zavřít</Trans>
            </Dialog.Confirm>
          </Dialog.Actions>
        </Dialog>
      ) : null}
    </CompanyEditContext.Provider>
  );
};

const useCompanyEditGuard = <T = () => void,>({ action }: { action: T }) => {
  const raiseIssue = useCompanyEditProvider();
  const { user: _user } = useAuth0();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(_user, "User should be defined");
  const user = getUser(_user);
  const company = user["can/app_metadata"]?.companies?.at(0);

  const canEdit = company?.grants.includes("COMPANY_DETAIL_EDIT");

  if (canEdit) {
    return action;
  }

  return raiseIssue;
};

export { useCompanyEditGuard, useCompanyEditProvider, CompanyEditGuard };
