import { FullscreenModalLayout } from "../layout";
import Cross from "@icons/cross.svg?react";
import CheckboxCheckedSvg from "@icons/checkbox.checked.svg?react";
import AngleRight from "@icons/angle-right.svg?react";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { getAbsolutePath } from "../routes.utils";
import { path as tradesPath } from "./trades";
import {
  Link,
  Navigate,
  Outlet,
  createSearchParams,
  generatePath,
  resolvePath,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { offerQuery } from "../shared/api/get-offer";
import invariant from "tiny-invariant";
import { useCurrency } from "@/common/hooks/use-currency";
import { Failed } from "@/common/components/info/info";
import { Metadata } from "@/common/components/metadata/metadata";
import { Spacer } from "@/common/components/spacer/spacer";
import { OfferPropertiesList } from "@/common/components/offer-properties-list/offer-properties-list";
import {
  currencyFormatter,
  numberFormatter,
} from "@/common/services/formatter";
import { storageQuery } from "../shared/api/get-storage";
import { initialDateFilter } from "@/common/components/quick-filters/quick-filters";
import React from "react";
import { z } from "zod";
import { DateTime } from "luxon";
import { PriceChart } from "./components/price-chart/price-chart";
import { Button, primary } from "@/common/components/button/button";
import { cropLastPricesQuery, ordersQuery } from "./trades.api";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { path as sellPath } from "./trades.detail.sell";
import { Spinner } from "@/common/components/spinner/spinner";
import AngleSmallDownSvg from "@icons/angle-small-down.svg?react";
import { OrderItem } from "@/common/components/order-item/order-item";
import { path as transactionsAndOrdersPath } from "../transactions-and-orders/transactions-and-orders";
import { path as orderPath } from "../transactions-and-orders/transactions-and-orders.order";
import { Trans, t } from "@lingui/macro";

type Fields = {
  marketPriceId: string;
};

const TradeDetail = () => {
  const form = useForm<Fields>();
  const { storageId, offerId } = useParams();
  const [, currency] = useCurrency();
  invariant(storageId);
  invariant(offerId);
  const storage = useSuspenseQuery(storageQuery(storageId));
  const offer = useSuspenseQuery(
    offerQuery({
      id: offerId,
    }),
  );
  const prices = useSuspenseQuery({
    ...cropLastPricesQuery({
      storageId,
      currency,
      cropId: offer.data?.crop.id,
    }),
  });

  const marketPriceId = form.watch("marketPriceId");
  const marketPriceMatch = prices.data?.find(
    (p) => p.marketPriceId === marketPriceId,
  );
  const marketPrice = marketPriceId
    ? marketPriceMatch
    : prices.data?.reduce((prev, cur) => (prev.price > cur.price ? prev : cur));
  const currentQuarter = (
    marketPriceMatch?.quarter.name ?? prices.data?.at(0)?.quarter.name
  )?.replace(" ", "/");

  return (
    <>
      <FullscreenModalLayout
        headerButton={
          <SearchParamsLink
            omit={["date-from", "date-to"]}
            to={getAbsolutePath(tradesPath)}
            replace
          >
            <Cross />
          </SearchParamsLink>
        }
      >
        <>
          <Metadata title={offer.data.crop.name} />
          <Spacer className="h-6" />
          <h1 className="text-2xl font-black text-can-forest-teal">
            {offer.data.crop.name}
          </h1>
          <Spacer className="h-6" />
          <OfferPropertiesList
            properties={[
              {
                label: <Trans>Farma</Trans>,
                value: storage.data?.company.name,
              },
              {
                label: <Trans>Sklad</Trans>,
                value: storage.data?.name,
              },
              {
                label: <Trans>Množství skladem</Trans>,
                value: numberFormatter().format(
                  offer.data.storage.offer.amount,
                ),
              },
              {
                label: <Trans>Celková hodnota</Trans>,
                value: prices.data.at(0)?.price
                  ? currencyFormatter(currency).format(
                      prices.data.at(0)!.price *
                        offer.data.storage.offer.amount,
                    )
                  : "-",
              },
            ]}
          />
          <Spacer className="h-6" />
          <Link
            className="flex justify-center gap-1 self-center text-center text-can-forest-teal"
            to={`../../storages/management/${storageId}`}
          >
            <Trans>Přejít do správy skladu</Trans>
            <AngleRight />
          </Link>
          <Spacer className="h-6" />
          <h2 className="font-bold text-can-forest-teal">
            <Trans>Vývoj aktuální ceny</Trans>
          </h2>
          <Spacer className="h-4" />
          {currentQuarter ? (
            <PriceChart
              quarter={currentQuarter}
              storageId={offer.data.storage.id}
              cropId={offer.data.crop.id}
            />
          ) : null}
          <Spacer className="h-6" />
          <form>
            <h2 className="font-bold text-can-forest-teal">
              <Trans>Prodejní ceny</Trans>
            </h2>
            <Spacer className="h-6" />
            <FormProvider {...form}>
              <PriceList
                prices={prices.data.map(({ marketPriceId, ...d }) => ({
                  ...d,
                  id: marketPriceId,
                  quarter: {
                    id: d.quarter.id,
                    name: d.quarter.name.replace(" ", "/"),
                  },
                  price: currencyFormatter(currency).format(d.price),
                }))}
              />
            </FormProvider>
          </form>
          <Spacer className="h-6" />
          <OrderList />
          <Spacer className="h-6" />
          <Button.Container>
            <SearchParamsLink to={sellPath} className={primary}>
              <Trans>Prodat</Trans>
            </SearchParamsLink>
          </Button.Container>
        </>
      </FullscreenModalLayout>
      <Outlet
        context={{
          marketPrice: {
            id: marketPrice?.marketPriceId,
            price: marketPrice?.price,
          },
          crop: offer.data?.crop,
          company: storage.data.company,
          storage: { id: storage.data.id, name: storage.data.name },
          offer: {
            amount: offer.data.storage.offer.amount,
          },
        }}
      />
    </>
  );
};

const OrderList = () => {
  const { offerId } = useParams();
  invariant(offerId);
  const orders = useQuery(ordersQuery({ offerId }));

  let Jsx = (
    <div className="mx-auto">
      <Spinner />
    </div>
  );

  if (orders.status === "error") {
    Jsx = <Failed error={orders.error} />;
  }

  if (orders.status === "success") {
    Jsx = (
      <div className="flex flex-col gap-y-6">
        <ul className="isolate flex flex-col gap-y-2">
          {orders.data.map((order) => (
            <OrderItem key={order.id} {...order}>
              <Link
                to={resolvePath(
                  generatePath(orderPath, {
                    offerId,
                    orderId: order.id,
                  }),
                  getAbsolutePath(transactionsAndOrdersPath),
                )}
              ></Link>
            </OrderItem>
          ))}
        </ul>
        <Link
          className="flex items-center justify-center text-can-forest-teal"
          to={getAbsolutePath(transactionsAndOrdersPath)}
        >
          <Trans>Ukázat všechny příkazy</Trans>
          <AngleSmallDownSvg className="-rotate-90 [&_path]:fill-can-forest-teal" />
        </Link>
      </div>
    );
  }

  if (orders.status === "success" && orders.data.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-4">
      <h2 className="font-bold text-can-forest-teal">
        <Trans>Aktivní příkazy</Trans>
      </h2>
      {Jsx}
    </div>
  );
};

type PriceListProps = {
  prices: {
    id: string;
    quarter: {
      id: string;
      name: string;
    };
    price: string;
  }[];
};

const PriceList = ({ prices }: PriceListProps) => {
  const form = useFormContext<Fields>();
  return (
    <ul className="flex w-full flex-col">
      {prices.map((price) => (
        <li
          className="border border-b-0 border-t border-can-silver-cloud border-t-can-silver-cloud first:rounded-tl-2xl first:rounded-tr-2xl first:border-l first:border-r first:border-l-can-silver-cloud first:border-r-can-silver-cloud last:rounded-bl-2xl last:rounded-br-2xl last:border-b last:border-l last:border-r last:border-l-can-silver-cloud last:border-r-can-silver-cloud has-[input:checked]:bg-can-silver-gray"
          key={price.quarter.id}
        >
          <label
            htmlFor={price.quarter.id}
            className="flex flex-wrap justify-between p-3 text-xs sm:text-base"
          >
            <span className="relative flex items-center gap-x-2">
              <input
                {...form.register("marketPriceId")}
                value={price.id}
                id={price.quarter.id}
                className="peer absolute left-0 top-0 h-0 w-0 opacity-0"
                type="radio"
                required
              />
              <span className="h-5 w-5 flex-shrink-0 rounded-full bg-can-silver-gray peer-checked:!invisible" />
              <CheckboxCheckedSvg className="invisible -ml-7 peer-checked:!visible" />
              <span>{price.quarter.name}</span>
            </span>
            <span>
              <Trans>TOP EKOS</Trans>
            </span>
            <b>{price.price}</b>
          </label>
        </li>
      ))}
    </ul>
  );
};

const params = createSearchParams({
  ...initialDateFilter,
});
const Params = ({ children }: React.PropsWithChildren) => {
  const searchParams = useTradeDetailParams();
  const { pathname, search } = useLocation();

  if (!searchParams) {
    return (
      <Navigate replace to={`${pathname}${search}&${params.toString()}`} />
    );
  }

  return <>{children}</>;
};

const useTradeDetailParams = () => {
  const [searchParams] = useSearchParams();
  const parsedParams = searchParamsSchema.safeParse(
    Object.fromEntries(searchParams),
  );

  if (parsedParams.success) {
    return parsedParams.data;
  }
};

const searchParamsSchema = z.object({
  "date-from": z
    .string()
    .regex(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)
    .nullish()
    .transform((v) => v ?? undefined),
  "date-to": z
    .string()
    .regex(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)
    .refine((date) => DateTime.now() > DateTime.fromISO(date), {
      message: (() => t`End date needs to come before today.`)(),
    }),
});

const path = ":storageId/offers/:offerId";

type Params = z.infer<typeof searchParamsSchema>;

export { TradeDetail, Params, useTradeDetailParams, path };
