import { currencyFormatter } from "@/common/services/formatter";
import { useQueryClient } from "@tanstack/react-query";
import { Dialog, useDialogProvider } from "../dialog/dialog";
import { Spinner } from "../spinner/spinner";
import TrashSvg from "@icons/trash.svg?react";
import { useRemoveOrderMutation } from "@/routes/shared/api/remove-order";
import { Plural, Trans } from "@lingui/macro";

type Props = {
  id: string;
  amount: number;
  currency: "EUR" | "CZK";
  quarter: {
    id: string;
    name: string;
  };
  price: number;
  className?: string;
};

/**
 * This one is tricky, but an `<a />` element is expected as a child. The component itself can't be wrapped in such element,
 * because of the trash bin button we have here.
 */
const OrderItem = (props: React.PropsWithChildren<Props>) => {
  const { openDialog, closeDialog } = useDialogProvider();
  const queryClient = useQueryClient();
  const removeOrder = useRemoveOrderMutation();
  const busy = removeOrder.status === "pending";

  const handleRemove = (orderId: string) => {
    closeDialog();
    removeOrder.mutate(
      { orderId },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries();
        },
      },
    );
  };

  const Jsx = (
    <Dialog>
      <Dialog.Title>
        <Trans>Chcete příkaz opravdu smazat?</Trans>
      </Dialog.Title>
      <Dialog.Actions>
        <Dialog.Confirm onClick={() => handleRemove(props.id)}>
          <Trans context="means to confirm">Ano</Trans>
        </Dialog.Confirm>
        <Dialog.Cancel onClick={closeDialog}>
          <Trans context="means to cancel">Ne</Trans>
        </Dialog.Cancel>
      </Dialog.Actions>
    </Dialog>
  );

  return (
    <>
      <li
        className={`relative flex min-h-[100px] cursor-pointer rounded-2xl px-4 py-3 shadow-can-light-box [&_a]:absolute [&_a]:inset-0 ${props.className ?? ""}`}
        key={props.id}
      >
        <div className="flex flex-col gap-y-2">
          <h3 className="font-bold text-can-midnight-steel">
            <Trans context="meaning of 'price bigger than'">Od</Trans>{" "}
            {currencyFormatter(props.currency).format(props.price)}
          </h3>
          {props.children}
          <p className="flex gap-1 text-xs">
            <span>{props.quarter.name.replace(" ", "/")}</span>
            <span aria-hidden>|</span>
            <span>
              <Plural
                _0="-"
                one="# tuna"
                two="# tuny"
                few="# tuny"
                other="# tun"
                value={props.amount}
              />
            </span>
            <span aria-hidden>|</span>
            <span>
              <Trans>Celkem za</Trans>{" "}
              {currencyFormatter(props.currency).format(
                props.price * props.amount,
              )}
            </span>
          </p>
        </div>
        <button
          disabled={busy}
          className="z-[1] -mr-2 ml-auto self-center rounded-full p-2 hover:bg-can-silver-gray disabled:cursor-wait"
          onClick={() => openDialog(Jsx)}
        >
          {busy ? (
            <Spinner className="!h-4 !w-4" />
          ) : (
            <TrashSvg className="h-4 w-4" />
          )}
        </button>
      </li>
    </>
  );
};

export { OrderItem };
