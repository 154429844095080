import { graphqlClient } from "@/api/graphql";
import { graphql } from "@/generated/digitalnisklady.cz";
import { queryOptions } from "@tanstack/react-query";
import { z } from "zod";

// eslint-disable-next-line lingui/no-unlocalized-strings
const storageQueryDocument = graphql(`
  query Storage($id: ID!) {
    storage(id: $id) {
      id
      label
      zip
      address
      offers {
        edges {
          node {
            yearHarvested
            id
            amount
            totalAmount
            orders {
              edges {
                node {
                  amount
                  active
                }
              }
            }
            crop {
              id
              name
            }
          }
        }
      }
      company {
        id
        name
      }
    }
  }
`);

type Storage = z.infer<typeof storageSchema>;
const storageSchema = z.object({
  address: z.string(),
  postalCode: z.string(),
  name: z.string(),
  id: z.string(),
  company: z.object({
    id: z.string(),
    name: z.string(),
  }),
  offers: z.array(
    z.object({
      harvestYear: z.number(),
      id: z.string(),
      totalAmount: z.number(),
      orderAmount: z.number(),
      amount: z.number(),
      crop: z.object({
        id: z.string(),
        name: z.string(),
      }),
    }),
  ),
});

const storageQuery = (id: string) =>
  queryOptions({
    queryKey: ["storage", id],
    queryFn: () =>
      graphqlClient.request(storageQueryDocument, {
        id,
      }),
    select: (data) => {
      const result = {
        name: data.storage?.label,
        postalCode: data.storage?.zip,
        id: data.storage?.id,
        company: data.storage?.company,
        address: data.storage?.address,
        offers: data.storage?.offers?.edges?.map((edge) => ({
          harvestYear: edge?.node?.yearHarvested,
          totalAmount: edge?.node?.totalAmount,
          amount: edge?.node?.amount,
          orderAmount: edge?.node?.orders?.edges
            ?.filter((order) => order?.node?.active)
            .reduce((sum, order) => sum + (order?.node?.amount ?? 0), 0),
          id: edge?.node?.id,
          crop: {
            id: edge?.node?.crop?.id,
            name: edge?.node?.crop?.name,
          },
        })),
      };

      return storageSchema.parse(result);
    },
  });

export { storageQuery };
export type { Storage };
